@use 'sass:math';
@import 'src/theme/theme';

.marketplaces-carousel {
  --swiper-theme-color: var(--bs-light);
  --swiper-pagination-bullet-inactive-color: var(--bs-color-3);
  --swiper-pagination-bullet-inactive-opacity: 1;
  --swiper-pagination-bullet-width: 40px;
  --swiper-pagination-bullet-height: 4px;
  --swiper-navigation-size: 32px;

  position: relative;

  &-inner {
    position: relative;
  
    @include media-breakpoint-up(sm) {
      margin: 0 50px;
    }
  }

  .container {
    @include media-breakpoint-down(sm) {
      padding: 0;
    }
  }
  
  .swiper {
    padding-bottom: 50px;

    @include media-breakpoint-down(sm) {
      overflow: auto;
      padding: 0 #{math.div($grid-gutter-width, 2)} 50px;
    }
  
    &-slide {
      background: var(--bs-color-3);
      border-radius: 6px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
      height: 138px;
      position: relative;
  
      &::after {
        border-radius: 6px;
        content: '';
        height: 100%;
        left: 0;
        opacity: 0.3;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: -1;
      }
    }

    &-button-next,
    &-rtl &-button-prev {
      display: none;
      right: -30px;
      top: calc(50% - 25px);

      @include media-breakpoint-up(sm) {
        display: block;
        right: -50px;
      }
    }

    &-button-prev,
    &-rtl &-button-next {
      display: none;
      left: -30px;
      top: calc(50% - 25px);

      @include media-breakpoint-up(sm) {
        display: block;
        left: -50px;
      }
    }

    &-pagination-bullet {
      border-radius: 40px;
    }
  }
  
  &-item {
    align-items: center;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    display: flex;
    height: 138px;
    justify-content: center;
    overflow: hidden;
    padding: 32px;
    
    &-logo {
      max-height: 64px;
      max-width: 100%;
      width: 250px;
    }
  }
}
