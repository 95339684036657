@import 'src/theme/theme';

.welcome {
  background-color: var(--bs-color-2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  padding: 22px 18px 22px 36px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 91px;
  }

  .row {
    justify-content: center;
  }

  &-image {
    border-radius: 6px;
    max-width: 100%;

    @include media-breakpoint-down(md) {
      margin-top: 20px;
    }
  }

  &-button {
    align-self: flex-start;
  }

  &-highlight {
    color: var(--bs-primary);
    font-family: $headings-font-family;
  }

  &-title {
    font-family: $headings-font-family;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &-description {
    color: var(--bs-silver);
    flex-grow: 1;
    font-size: 16px;
    font-weight: 400;
  }
  
  &-visual-1 {
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-40%) translateY(-50%);
    width: 221px;
  }

  &-visual-2 {
    left: 100%;
    position: absolute;
    top: 90%;
    transform: translateX(-50%) translateY(-50%);
    width: 360px;
  }
}
