@import 'src/theme/theme';

.cta-section {
  margin-bottom: 90px;
  margin-top: 70px;
  position: relative;

  @include media-breakpoint-up(lg) {
    margin-bottom: 151px;
    margin-top: 100px;
  }

  .row {
    margin-left: -6px;
    margin-right: -6px;

    > div {
      padding-left: 6px;
      padding-right: 6px;
    }
  }

  &-top {
    margin: 0 auto;
    max-width: 1600px;
    position: relative;
  }
  
  &-title {
    font-family: $headings-font-family;
    font-size: 38px;
    font-weight: 700;
    line-height: 1.2;
    margin: 0 auto 17px;
    text-align: center;
    
    @include media-breakpoint-up(lg) {
      font-size: 72px;
    }

    span {
      color: var(--bs-primary);
    }

  }

  &-subtitle {
    color: var(--bs-silver);
    font-size: 18px;
    line-height: 1.15;
    margin: 0 auto 100px;
    max-width: 550px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 21px;
      margin-bottom: 151px;
    }
  }
}
