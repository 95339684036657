@import 'src/theme/theme';

.nft-video {
  z-index: 50;


  .video-js {
    align-items: center;
    align-self: stretch;
    display: flex;
    justify-content: center;
  }

  .vjs-tech {
    display: none;
  
    height: auto;
    max-height: calc(100vh - 90px);
    max-height: 100%;
    max-width: 100%;
    pointer-events: auto;
    position: relative;
    width: auto;
  }
}
