@font-face {
  font-display: swap;
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 400;
  src: url('https://cdn.multiversx.com/fonts/roobert/light.woff2') format('woff2'),
    url('https://cdn.multiversx.com/fonts/roobert/light.woff') format('woff'),
    url('https://cdn.multiversx.com/fonts/roobert/light.otf') format('opentype');
}

@font-face {
  font-display: swap;
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 600;
  src:url('https://cdn.multiversx.com/fonts/roobert/semibold.woff2') format('woff2'),
    url('https://cdn.multiversx.com/fonts/roobert/semibold.woff') format('woff'),
    url('https://cdn.multiversx.com/fonts/roobert/semibold.otf') format('opentype');
  
}

@font-face {
  font-display: swap;
  font-family: 'Roobert';
  font-style: normal;
  font-weight: 700;
  src:url('https://cdn.multiversx.com/fonts/roobert/bold.woff2') format('woff2'),
    url('https://cdn.multiversx.com/fonts/roobert/bold.woff') format('woff'),
    url('https://cdn.multiversx.com/fonts/roobert/bold.otf') format('opentype');
  
}
