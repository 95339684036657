@import '/src/theme/theme';

.nft-media {
  height: 100vh;
  position: sticky;
  top: 0;
  width: 100%;

  @include media-breakpoint-up(lg) {
    width: calc(100% - $sidebar-width);
  }

  .swiper {
    height: 100%;
    pointer-events: none;
    position: absolute;
    width: 100%;
  
    .swiper-slide {
      align-items: center;
      display: flex;
      justify-content: center;
      max-width: calc(100% - 120px);
      pointer-events: none;
      width: 100% !important;

      @include media-breakpoint-up(md) {
        max-width: calc(100% - 180px);
      }

      > * {
        pointer-events: all;
      }
  
      &.loaded {
        width: auto !important;
      }
  
      img,
      video {
        max-height: calc(100vh - 90px);
        max-width: 100%;
      }
    }
  
    .swiper-button-prev,
    .swiper-button-next {
      margin: 0;
      pointer-events: all;
  
      &::after {
        background-color: var(--bs-white);
      }
    }
  }

  .report-button {
    bottom: 45px;
    color: var(--bs-silver);
    position: absolute;
    right: 16px;

    @include media-breakpoint-up(lg) {
      bottom: 16px;
    }
  }
  
}

.nft-media-background {
  background-color: var(--bs-body-bg);
  height: 100%;
  left: 0;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: 100%;
}
