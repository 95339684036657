@import './src/theme/theme';

.asset-card {
  &-price {
    @include gradient-box($border-radius: 50px, $border-width: 1px);
    bottom: 12px;
    left: 12px;
    position: absolute;

    &-inner {
      align-items: center;
      display: flex;
      padding: 15px;

      @include media-breakpoint-up(md) {
        padding: 15px 18px;
      }
      
      &.has-icon {
        padding-bottom: 3px;
        padding-left: 3px;
        padding-top: 3px;
      }
    }

    &-icon {
      align-items: center;
      display: flex;
      height: 48px;
      justify-content: center;
      width: 48px;

      @include media-breakpoint-up(md) {
        height: 64px;
        width: 64px;
      }

      img {
        height: 60%;
        object-fit: contain;
        object-position: center;
        width: 60%;
      }
    }
  }

  &-prices {
    font-size: 12px;
    line-height: 1;
    margin-left: 6px;

    @include media-breakpoint-up(md) {
      font-size: 18px;
    }
  }

  &-price-value {
    line-height: 1;
    margin-bottom: 3px;
    white-space: nowrap;

    b {
      font-size: 1.11em;
      font-weight: 600;
    }
  }

  &-price-usd {
    
    opacity: 0.7;
    white-space: nowrap;
  }
}
