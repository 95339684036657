.admin-sidebar {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: fit-content;
    position: fixed;
    right: 0;
    top: 200px;
    z-index: 100;
  }

  &-content {
    align-items: center;
    border-bottom-left-radius: 8px;
    border-top-left-radius: 8px;
    display: flex;
    flex-direction: column;

    .dropdown {
      font-size: 12px;
      
      &-toggle {
        font-size: 12px;
        padding: 8px 12px;
      }

      &-menu {
        background-color: transparent;
        text-align: right;

        .btn-secondary {
          border-radius: 100px;
          color: var(--bs-primary);
          display: inline-block;
          padding: 8px 12px;
          white-space: nowrap;
        }
      }
    }
  }
}
