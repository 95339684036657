@import 'src/theme/theme';

.collection-filters-assets {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;

  .traits-button {
    margin-left: 10px;
  }
}
