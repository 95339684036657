/* stylelint-disable selector-class-pattern */

.extension-modal {
  &_wrapper {
    .modal-content {
      box-shadow: 0 0 24px rgba(0, 0, 0, 0.4);
    }

    .modal-dialog {
      max-width: 560px;
    }
  }

  &_iconContainer {
    align-items: center;
    border-radius: 40px;
    display: inline-flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    svg {
      height: auto;
      width: 20px;
    }
  }
}

.dapp-core-component {
  &__dappModalStyles__dappModalContent {
    background-color: var(--bs-color-1) !important;
    border-color: var(--bs-color-1) !important;
  }

  &__main__data-field .dapp-core-component__main__textarea {
    background-color: var(--bs-body-bg) !important;
    border: 1px solid rgba(var(--bs-secondary-rgb), 0.3) !important;
  }

  &__transactionsToastList-styles__toasts {
    left: 20px !important;
    right: auto !important;

    &.dapp-core-component__transactionsToastList-styles__toastWrapper {
      background-color: var(--bs-color-1) !important;
      position: fixed !important;
    }
  }

  &__main__btn {
    @extend %btn-primary;
    background-color: var(--bs-secondary) !important;
    border-radius: $btn-border-radius !important;
    color: var(--bs-primary) !important;
    padding: $btn-padding-y $btn-padding-x !important;

    .svg-inline--fa {
      color: var(--bs-primary) !important;
    }

    &:hover,
    &:active,
    &:focus,
    &:focus:active {
      background-color: var(--bs-color-4) !important;
      text-decoration: none !important;
    }
  }

  &__styles__close {
    color: var(--bs-silver) !important;
  }

  &__main__link-style {
    color: var(--bs-primary) !important;
  }

  &__main__pair-list {
    gap: 1rem;

    .dapp-core-component__main__btn-light {
      border: var(--bs-color-1) !important;
      border-radius: 0.5rem !important;
      color: unset !important;
      margin-bottom: 0 !important;

      &:hover {
        border-color: var(--bs-primary) !important;
      }

      .dapp-core-component__main__pair-loader,
      .dapp-core-component__main__pair-image {
        width: 3rem;
      }
    }
  }
}

.dapp-ledger-address-table {

  font-size: 12px;
  margin-bottom: 1.5rem !important;
  padding: 2rem 0 1rem !important;

  .dapp-core-component__main__card-body {
    padding: 0 !important;
  }

  .dapp-core-component__main__form-check-label {
    padding-left: 1.5rem !important;
  }

  .dapp-core-component__main__form-check-input {
    margin-left: 0 !important;
  }

  .dapp-core-component__addressRowStyles__ledger-address-table-body-item .dapp-core-component__addressRowStyles__ledger-address-table-body-item-data,
  .dapp-core-component__trim-styles__trim .dapp-core-component__trim-styles__left span,
  .dapp-core-component__trim-styles__trim .dapp-core-component__trim-styles__right span {
    font-size: 12px !important;
  }

  .dapp-core-component__addressRowStyles__ledger-address-table-body-item .dapp-core-component__addressRowStyles__ledger-address-table-body-item-data:first-child {
    flex-basis: 100% !important;
    max-width: 77% !important;
    width: 100% !important;
  }

}

.dapp-core-component__addressTableStyles__ledger-address-table-wrapper .dapp-core-component__addressTableStyles__ledger-address-table .dapp-core-component__addressTableStyles__ledger-address-table-header .dapp-core-component__addressTableStyles__ledger-address-table-header-item:first-child {
  flex-basis: 100% !important;
  max-width: 77% !important;
  width: 100% !important;
}

.dapp-core-component__addressTableStyles__ledger-address-table-wrapper .dapp-core-component__addressTableStyles__ledger-address-table {
  background-color: var(--bs-color-1) !important;
  border-radius: 6px !important;
}

.dapp-core-component__addressTableStyles__ledger-address-table-wrapper .dapp-core-component__addressTableStyles__ledger-address-table .dapp-core-component__addressTableStyles__ledger-address-table-header {
  background-color: var(--bs-color-1) !important;
}

.dapp-core-component__progressBarStyles__ledger-progress-bar .dapp-core-component__progressBarStyles__ledger-progress-bar-track {
  background-color: var(--bs-color-1) !important;
  max-width: 100% !important;
  width: 100% !important;
}

.dapp-core-component__progressBarStyles__ledger-progress-bar .dapp-core-component__progressBarStyles__ledger-progress-bar-thumb {
  background-color: var(--bs-primary) !important;
}

.dapp-core-component__ledgerConnectStyles__login-connect-container .dapp-core-component__ledgerConnectStyles__login-connect-container-content .dapp-core-component__ledgerConnectStyles__login-connect-container-footer .dapp-core-component__ledgerConnectStyles__login-connect-container-link {
  color: var(--bs-light);
  font-size: 12px !important;
}
