@import 'src/theme/theme';

.marketplaces-section {
  margin-bottom: 0;
  margin-top: -86px;
  padding: 120px 0 70px;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-top: -202px;
    padding: 230px 0 170px;
  }

  &-bg {
    background-image: url('/startup/assets/cta-bg.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 150%;
    left: 50%;
    min-height: 685px;
    padding-top: 46%;
    pointer-events: none;
    position: absolute;
    top: 55%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    z-index: -1;
  
    @include media-breakpoint-up(md) {
      background-position: center;
      background-size: 1440px;
    }
  }
}
