@import './src/theme/theme';

.layout {
  overflow: hidden;
  padding-top: #{$header-height-mobile + 30px};

  @include media-breakpoint-up(md) {
    padding-top: #{$header-height + 65px};
  }
}
