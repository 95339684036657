.btn-control {
  border: 0;
  cursor: pointer;
  position: absolute;
  width: 40px;
  z-index: 52;

  span {
    align-items: center;
    backdrop-filter: blur(24px);
    background-color: var(--bs-color-3);
    border-radius: 20px;
    display: flex;
    height: 40px;
    justify-content: center;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    width: 40px;
  }

  &.type-close {
    left: 16px;
    top: 16px;
    z-index: 53;

    span {
      position: static;
      transform: none;
    }
  }

  &.type-settings {
    right: 16px;
    top: 16px;
  }

  &.type-left,
  &.type-right {
    left: 15px;
    top: 50%;
  }

  &.type-right {
    left: auto;
    right: 15px;
  }

  svg path {
    fill: var(--bs-silver);
  }

  &:hover svg path {
    fill: var(--bs-light);
  }  
}
