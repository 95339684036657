@import 'src/theme/theme';

.subscribe-newsletter {
  background-color: var(--bs-color-2);
  border-radius: 6px;
  justify-content: center;
  margin-bottom: 100px;
  min-height: 140px;
  padding: 32px 16px;

  @include media-breakpoint-up(md) {
    padding: 32px;
  }

  &-title {
    font-size: 22px;
    font-weight: 400;
    line-height: 22px;
    margin-bottom: 32px;
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      text-align: left;
    }
  }

  &-form form {
    position: relative;
  }

  &-form input {
    background-color: var(--bs-black);
    border: 0;
    border-radius: 65px;
    color: var(--bs-primary);
    margin-bottom: 15px;
    padding: 16px 24px;
    width: 100%;

    @include media-breakpoint-up(sm) {
      margin-bottom: 0;
      padding: 20px 24px;
      padding-right: 150px;
    }
  }

  &-form input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    background-color: var(--bs-black);
    border: 0;
    box-shadow: none;
    -webkit-text-fill-color: var(--bs-primary);
    transition: background-color 5000s ease-in-out 0s;
  }

  &-form button {
    background-color: var(--bs-primary);
    border: 0;
    border-radius: 54px;
    color: var(--bs-black);
    height: 48px;
    outline: none;
    width: 100%;

    @include media-breakpoint-up(sm) {
      position: absolute;
      right: 7px;
      top: 50%;
      transform: translateY(-50%);
      width: 129px;
    }
  }

  &-form input:focus,
  input:focus-visible {
    border: 0;
    outline: none;
  }

}
