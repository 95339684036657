@import './src/theme/theme';

.footer {
  &-section-link {
    color: var(--bs-light);
    cursor: pointer;
    font-size: 14px;
    text-decoration: none;
    vertical-align: middle;

    &:hover {
      color: var(--bs-light);
      text-decoration: underline;
    }
  }

  .free-label {
    vertical-align: bottom;
  }
  
  &-fire-image {
    max-height: 27px;
    max-width: 27px;
    padding: 4px 6px;
    pointer-events: none;
    text-align: center;
  }
}
