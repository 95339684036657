@import '/src/theme/theme';

.report-button {
  backdrop-filter: blur(24px);
  background-color: var(--bs-color-3);
  color: var(--bs-white);
  transition: all 0.3s ease-in-out;
  z-index: 55;

  svg {
    margin-right: 8px;

    path {
      transition: none;
    }
  }

  &-check {
    color: var(--bs-primary);
  }

  &:hover,
  &:active,
  &:focus,
  &:focus:active {
    background-color: var(--bs-color-4);
    box-shadow: none;
    color: var(--bs-red);
  }

  &:focus-visible {
    background-color: var(--bs-color-4);
    box-shadow: none;
    outline: none;
  }

  &:disabled {
    cursor: unset;
    pointer-events: auto;

    &:hover {
      background-color: var(--bs-color-3);
      color: var(--bs-silver);

      svg {
        color: inherit;
      }
    }
  }
}
