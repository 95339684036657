.sidebar-connect .maiar-qr-code {
  .dapp-core-component__main__card {
    background: var(--bs-body-bg);
    border: 0;
  }
  
  .dapp-core-component__main__text-primary {
    color: var(--bs-primary) !important; 
  }
}
