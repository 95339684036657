@import 'src/theme/theme';

.cta-section-bg {
  background-image: url('/startup/assets/cta-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
  left: 50%;
  min-height: 685px;
  padding-top: 46%;
  pointer-events: none;
  position: absolute;
  top: 7%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  z-index: -1;

  @include media-breakpoint-up(md) {
    background-position: center;
    background-size: 1440px;
    top: 13%;
  }

  &-line {
    background-image: url('/startup/assets/cta-line.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 1000px;
    display: none;
    left: 50%;
    min-height: 685px;
    padding-top: 46%;
    pointer-events: none;
    position: absolute;
    top: 92%;
    transform: translateY(-50%) translateX(-50%);
    width: 100%;
    z-index: -1;
  

    @include media-breakpoint-up(md) {
      display: block;
    }

    @include media-breakpoint-up(lg) {
      background-size: 1440px;
    }
  }
}
