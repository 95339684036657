@use 'sass:math';
@import './src/theme/theme';

.account-tabs {
  font-size: 16px;
  margin-top: 48px;

  .page-tabs-filters {
    .account-create-buttons {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: -10px;
    }

    .account-create-button-wrapper {
      margin-bottom: 10px;
      
      &:not(:last-child) {
        margin-right: 16px;

        @include media-breakpoint-up(lg) {
          margin-left: 16px;
        }
      }
    }
  }
}
