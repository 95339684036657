@import 'src/theme/theme';

.featured-roadmap-steps {
  margin-bottom: 60px;
  padding-bottom: 40px;
  position: relative;

  @include media-breakpoint-up(md) {
    margin-bottom: 150px;
  }

  .container {
    position: relative;

    &::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, #fbca1c 30%, #8533ff 70%, rgba(0, 0, 0, 0) 100%);
      bottom: 0;
      content: '';
      left: 16px;
      position: absolute;
      top: 0;
      width: 3px;
      z-index: -1;

      @include media-breakpoint-up(md) {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.featured-roadmap-step {
  margin-bottom: 50px;

  @include media-breakpoint-up(md) {
    margin-bottom: 50px;
  }

  &-quarter {
    background: var(--bs-white);
    border: 3px solid var(--bs-body-bg);
    border-radius: 50px;
    display: inline-block;
    height: 13px;
    width: 13px;

    @include media-breakpoint-down(md) {
      margin-left: -1px;
      transform: translateY(70px);
    }
  }

  &-title {
    color: var(--bs-white);
  }

  &-left {
    align-content: start;
    background-color: var(--bs-color-2);
    border-radius: 6px;
    color: var(--bs-silver);
    font-size: 16px;
    font-weight: 400;
    margin-left: auto;
    max-width: 545px;
    padding: 40px 24px 40px 40px;
    text-align: left;

    @include media-breakpoint-down(md) {
      align-content: start;
      margin-bottom: 16px;
      margin-left: 25px;
      text-align: left;
    }

    ul li {
      list-style-position: inside;
      margin-bottom: 0;
      text-align: left;

      @include media-breakpoint-down(md) {
        align-content: start;
        text-align: left;
      }
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &-right {
    background-color: var(--bs-color-2);
    border-radius: 6px;
    color: var(--bs-silver);
    font-size: 16px;
    font-weight: 400;
    max-width: 545px;
    padding: 40px 24px 40px 40px;
    text-align: left;

    @include media-breakpoint-down(md) {
      align-content: start;
      margin-bottom: 16px;
      margin-left: 25px;
    }

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
